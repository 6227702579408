import React from "react";

import { Outer } from "./styled";
import { Container, Markdown } from "../global";

import HeroBG from "../../images/hero-bg.jpg";
import Logo from "../../images/logo.svg";

interface Props {
    content: string;
}

const HeroHome = (props: Props) => {
    return (
        <Outer img={HeroBG}>
            <Container>
                <img src={Logo} alt="" />
                <Markdown className="test" input={props.content}></Markdown>
            </Container>
        </Outer>
    );
};

export default HeroHome;
