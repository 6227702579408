import styled from "styled-components";
import { md } from "../../constants/MediaQueries";
import { navy } from "../../constants/Colors";

export const Outer = styled.div<{ img: string }>`
	background-color: ${navy};
    padding: 50px 0;
	background-image: url('${({ img }) => img}');
	background-size: cover;
	background-position: center;
	text-align: center;
	color: white;

	img {
		width: 100%;
		max-width: 125px;
		margin-bottom: 24px;
	}

    ${md} {
        padding: 100px 0;

		img {
			max-width: 200px;
			margin-bottom: 50px;
		}
    }
`;
