import React from "react";
import styled, { css } from "styled-components";
import { md } from "../../constants/MediaQueries";
import { Container, Markdown } from "../global";
import AboutBG from "../../images/about-bg.png";

export enum Appearances {
    Solid = "solid",
    Image = "image"
}

interface Props {
    content: string;
    appearance: Appearances;
}

export const Outer = styled.div<{ appearance: Appearances }>`
    padding: 50px 0;

    ${({ appearance: style }) => {
        switch (style) {
            case Appearances.Image:
                return css`
                    background-image: url(${AboutBG});
                    background-position: center;
                    background-size: cover;
                    color: white;
                `;
            case Appearances.Solid:
            default:
                return css`
                    background-color: rgba(0, 0, 0, 0.05);
                `;
        }
    }}

    ${md} {
        padding: 100px 0;
    }
`;

export const Inner = styled.div`
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
`;

const HomeSection = (props: Props) => {
    return (
        <Outer appearance={props.appearance}>
            <Container>
                <Inner>
                    <Markdown input={props.content} />
                </Inner>
            </Container>
        </Outer>
    );
};

export default HomeSection;
