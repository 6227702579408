import React from "react";
import Layout from "../components/layout";
import HeroHome from "../components/HeroHome";
import HomeSection, { Appearances } from "../components/HomeSection";
import Footer from "../components/Footer";
import { graphql } from "gatsby";
import AlertBar from "../components/AlertBar";

const IndexPage = props => {
    const {
        about,
        contact,
        header
    } = props.data.file.childMarkdownRemark.frontmatter;
    return (
        <Layout>
            <HeroHome content={header} />
            <HomeSection content={about} appearance={Appearances.Image} />
            <HomeSection content={contact} appearance={Appearances.Solid} />
        </Layout>
    );
};

export const query = graphql`
    {
        file(sourceInstanceName: { eq: "pages" }, name: { eq: "home" }) {
            name
            childMarkdownRemark {
                frontmatter {
                    title
                    header
                    about
                    contact
                }
            }
        }
    }
`;

export default IndexPage;
